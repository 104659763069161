import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm from "@tightrope/trbm"

const prdbestData = import("./data/data.json");

export default function prdbest() {
  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
    body {
      background-image: url(/img/convertorfull-dark.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      background-position-x: center;
    }

    .footerlinks-module--br--2T_Dp,
     .footerlinks-module--br--2T_Dp a{
      color: rgba(255,255,255,0.6);
    }

    .trbm_cta {
        padding: 15px 100px;
        border: none;
        font-size: 35px;
        color: #fff;
        font-weight: bold;
        background: #43ba19;
        border-radius: 10px;
        cursor: pointer;
        transition: .2s ease;
    }
    `}
    </style>
    <title>Print any web page easily - Easy View</title></Helmet>
    <section>
      <Trbm data={prdbestData}></Trbm>
      </section>
    </HomepageLayout>
  )
}
